/*

Template: Hi-soft - IT Solutions and Services Company HTML5 Template
Version: 1.0
Author: potenzaglobalsolutions
Design and Developed by: potenzaglobalsolutions.com

NOTE: This is main stylesheet of template, This file contains the styling for the actual Template.

*/

/*================================================
[  Table of contents  ]
================================================

 :: Typography
 :: Helper Classes
 :: Shortcodes
  	- Accordion
  	- Button
  	- Case Study
    - Category
    - Client
    - Countdown
    - Counter
    - Feature Info
    - Form Control
    - List style
    - Newsletter
    - Owl Carousel
    - Portfolio
    - Pricing Table
    - Progress Bar
    - Tabs
    - Team
    - Testimonial
    - Timeline
 :: Header
   - Header
   - Header Style 02
   - Header Style 03
   - Header Transparent
 :: Banner
   - Banner
   - banner 02
   - banner 03
 :: Layout
   - Section Title
   - Sticky column
   - Header Inner
 :: Blog
   - Blog
   - blog post sticky
 :: Not Found
 :: Footer
 :: Responsive

======================================
[ End table content ]
======================================*/

// Core files
@import "variables";
@import "typography";
@import "helpers";

// Shortcodes
@import "shortcodes/accordion";
@import "shortcodes/feature-info";
@import "shortcodes/team";
@import "shortcodes/testimonial";
@import "shortcodes/accordion";
@import "shortcodes/newsletter";
@import "shortcodes/counter";
@import "shortcodes/tabs";
@import "shortcodes/owl-carousel";
@import "shortcodes/pricing";
@import "shortcodes/countdown";
@import "shortcodes/button";
@import "shortcodes/case-study";
@import "shortcodes/category";
@import "shortcodes/client";
@import "shortcodes/form-control";
@import "shortcodes/portfolio";
@import "shortcodes/timeline";
@import "shortcodes/progress-bar";
@import "shortcodes/list";

// Structure
@import "header";
@import "banner";
@import "layout";

// Pages
@import "blog";
@import "footer";

// Responsive
@import "responsive";

html { 
  visibility:hidden; 
}

ul{
list-style-type: none;}

/*.navi{

  ul{
    display: flex;
    //-ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    li{
      color:#022d62;
      padding: 14px 40px;
      font-weight: 600;
      a{
      color:#022d62;
      padding: 14px 40px;
      font-weight: 600;
        &:hover{
          color:#F38A8A;
        }
       }      
    }
    .active{
       a{
         color:#F38A8A;
         //border-bottom:3px solid #F38A8A;
        }
      }
  }
}*/
.hr-disp{
width: 100%;
color: black;
height: 1px;
background-color: #92d5f0;
margin: auto;
border-top-color: #92d5f0 !important;
position: absolute;
margin-left: auto;
margin-right: auto;
left: 0;
right: 0;
text-align: center;}

.font-bold{
  font-weight: bold;
}

.pt-70{
  padding-top: 70px;
}


.line-h-font-w{
  line-height: 1.5;
  font-weight: normal;
}
.line-h-font-w-2{
  font-size: 17px;
  line-height: 1.5;
  font-weight: normal;
}

.footer-font-kns-color{
color: white !important;}
.footer-font-kns-color:hover{
   color: #1d4279 !important;
 }

 .font-kns-quality-color{
  color: #1d4279  !important;
  font-weight: 650 !important;}
  .font-kns-quality-color:hover{
     color: #f34e57 !important;
   }

.height-30{
  height: 30px;
  margin-top: 25px;
}

.offer-head{
  font-size: 52px;
  font-weight: 600;
  text-align: center;
  line-height: 518px;
}
.offer-head p {
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
}
.feature-info-kns-icon{
  position: relative;float: left;margin-top: 20px;
}
.ai-normal{
  align-items: normal;
}

.pt-60{
  padding-top: 60px;
}
input[type="file"] {
  display: none;
}
.section-bgr-exp-right-img-3 {
  background-image: url("/images/realizacje/step_section_rlz_bgr.png");
  background-color: #bdd0fb;
  background-size: cover;
  background-repeat: no-repeat;
}

.section-img {
  background-image: url("/images/realizacje/step_third_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.b-r-10{
  border-radius: 10px;
}

.height-100{
  height: 80px;
}

.margin-top-300{
  margin-top: 400px;
}
.margin-top-90{
  margin-top: 200px;
}

.img-section{
  width: 300px;
  position: relative;
  max-width: 100%;
  height: auto;
}

.bg-index-dlm{
  background-image: url("/images/index/bg_index_dlm.png");
  //position: relative;
  //max-width: 100%;
  //height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #019fe3;
}

.bg-color-idea{
  background-color: #dce4fb;
}

.icon-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  text-align: center;
  background: #ffffff;
  border-radius: 50%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #022d62; }

  .icon-btn:hover {
background: #022d62;
color: #ffffff; }


.color-white{
  color: white;
}
.bottom-absolute{
  bottom: 0px;
  position: absolute;
}

#zdj:hover
{
    background-image: url(http://img.mynet.com/ha2/tayyip.jpg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.h3_span{
  background-color: #7f9edc;
  font-size: 43px;
  color: white;
  
}

.h3_span_2{
  background: rgba(127,158,220,0.7);
  font-size: 43px;
  color: white;
}

.h1_span{
  background: rgba(127,158,220,0.7);
  font-size: 63px;
  color: white;
}

.section-bgr {
  background-color: #ecf2ff;
  background-size: cover;
  background-repeat: no-repeat;
}

.div-recomendation{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 165px;
}

.color-dc {
  background-color: #dce4fb;
}

.section-bgr-case-study {
  background-image: url("/images/jak_pracujemy/bg_case_study.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #dce4fb;
}

.section-bgr-case-study-2 {
  background-image: url("/images/jak_pracujemy/bg_case_study.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #dce4fb;
  //position: relative;
}
.section-bgr-op {
  background-image: url("/images/index/bg_index_op.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  max-width: 100%;
  min-height: 700px;
  height: auto;
}

.section-bgr-recomendation {
  background-image: url("/images/index/bg-recomendation.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  max-width: 100%;
  min-height: 700px;
  height: auto;
}

.section-bgr-form-contact {
  background-image: url("/images/kontakt/bgr_form_contact.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #dde5fb;
}
.section-bgr-exp-right-img-contact{
  background-image: url("/images/kontakt/bgr_baner_contact.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.section-bgr-exp-right-img-2 {
  background-image: url("/images/realizacje/step_section_rlz_bgr.png");
  background-color: #bdd0fb;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 594px;
}
.bg-holder-1 {
  background-repeat: no-repeat;
  background-position: center center;
  max-width: 100%;
  height: auto;
}

.footer-bg-color{
  background-color: #25aae1;
}

.footer .footer-link ul li:first-child a {
  padding-top: 0;
}
.footer .footer-link ul li a {
  color:#ffffff;
  font-weight: 500;
  padding: 7px 0px;
  display: block;
}
a {
  outline: medium none !important;
  color: #fe766a;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.bg_op_sdp{
  background-color: #1e396d;
  border-radius: 10px;
}

.bg_op_gpe{
  background-color: #c7c2db;
  border-radius: 10px;
}

.bg_op_mafka{
  background-color: #f9b2ac;
  border-radius: 10px;
}

.bg_op_rydoo{
  background-color: #8282dc;
  border-radius: 10px;
}

.bg_op_jas{
  background-color: #74bf03;
  border-radius: 10px;
}

.clients-detail {
  margin: 0; }
  .clients-detail li {
    padding-right: 150px;
    margin-top: 0px; }
    .clients-detail li:last-child {
      padding-right: 0px; }


.header-inner .header-inner-nav .nav li a.active {
    //border-bottom: 2px solid #fe766a;
    //padding: 16px 14px !important;
    //color: #fe766a !important;
    background: none;
}

.position-relative {
  position: relative;
}

.header-inner {
  z-index: 99; }

.header-inner .header-inner-nav.page-menu-top {
  position: sticky;
  width: 100%;
  background: #ffffff;
  
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
          box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  top: 70px;
  -webkit-animation: stickySlideDown 0.85s cubic-bezier(0.23, 1, 0.32, 1) both;
          animation: stickySlideDown 0.85s cubic-bezier(0.23, 1, 0.32, 1) both;
  padding: 17px 0; }

.header-inner .header-inner-nav.page-menu-top .nav li a {
  color: #022d62;
  padding: 10px 40px; }

.header-inner .header-inner-nav .nav li a {
  color: #022d62;
  padding: 14px 40px;
  font-weight: 600;
  border-radius: 5px; }

.header-inner .header-inner-nav {
  padding: 15px 0;
  /*background: -webkit-gradient(linear, left bottom, left top, color-stop(20%, black), color-stop(100%, rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0)));
  background: linear-gradient(0deg, black 20%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 100%);*/
  background: white; }

.header-inner {
  padding: 0px 0px;
  background-size: cover;
  background-position: center center; }


.section-bgr-resp {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  max-width: 100%;
  height: auto;
}

.section-bgr-resp-map {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  max-width: 100%;
  height: 100%;
}

.section-header-bgr-l{
  background-color: #dee6fb;
}
.section-bgr-resp-2 {
  width: 100%;
}
.section-bgr-jas {
  background-image: url("/images/realizacje/jas/bg_jas.png");
  background-color: #dee6fb;
  background-size: cover;
  background-repeat: no-repeat;
}

.home-icon-active {
  background: url(/images/home-white-24dp.png);
  background-repeat: no-repeat;
  background-size: 16px 13px;
  background-position-x: center;
  height: 16px;
  width: 16px;
  display: block;
  margin-top: 5px;
}

.home-icon {
  background: url(/images/home-white-24dp-1.png);
  background-repeat: no-repeat;
  background-size: 16px 13px;
  background-position-x: center;
  height: 16px;
  width: 16px;
  display: block;
  margin-top: 5px;
  fill: midnightblue;
}

.phone-icon {
  background: url(/images/call-white-24dp.png);
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position-x: center;
  height: 16px;
  width: 35px;
  display: block;
  margin-top: 5px;
}

.custom-nav {
  li {
    padding: 0 40px;
  }
}

.logo {
  img {
    height: 25px;
  }
}

.active-nav {
  border-bottom: 2px solid $primary;
  padding: 24px 0px !important;
  color: $primary !important;
}

.section-bgr-exp-right-img {
  background-image: url("/images/section-bgr.png");
  background-color: #fcfcfc;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 577px;
}

.phone a {
  font-size: 16px !important;
}

.padding-left-50 {
  padding-left: 50px;
}

.our-exp-section-img {
  position: absolute;
  top: -60px;
  left: -50px;
  width: 900px;
}

.our-exp-section-img-hww {
  position: absolute;
  top: -55px;
  left: -50px;
  width: 900px;
}

.our-exp-section-img-contact {
  position: absolute;
  top: 0px;
  left: -50px;
  width: 800px;
}

.our-exp {
  color: black !important;
  white-space: nowrap;
  h1, h2, h3 {
    margin-bottom: 0;
    line-height: 1.2;
    //white-space: nowrap;
  }
}

.our-exp-descr {
  font-size: 24px;

  p {
    margin-bottom: 0;
    line-height: 1.5;
  }
}

.section-descr {
  padding: 11% 0 0 18%;
}

.mt-50 {
  margin-top: 50px;
}



.since_2019_image {
  width: 700px;
  //padding-top: 30px;
  float: right;
}

.since_2019_image_small {
  width: 500px;
  //padding-top: 30px;
  float: right;
}
.since_2019_image_l{
  width: 700px;
  float: left;
}

.triangle_bf_text-2 {
  padding-left: 3px;
  padding-bottom: 15px;

  &:before {
    content: url("/images/triangle_left.png");
    position: absolute;
    top: 20px;
    left: -37px;
  }
}

.triangle_bf_text {
  padding-left: 3px;
  //padding-bottom: 15px;

  &:before {
    content: url("/images/triangle_left.png");
    position: absolute;
    top: -10px;
    left: -37px;
  }
}

.triangle_bf_text_white {
  padding-left: 3px;
  //padding-bottom: 15px;
  font-weight: lighter;

  &:before {
    content: url("/images/triangle_left_white.png");
    position: absolute;
    top: -13px;
    left: -40px;
  }
}

.our-exp-head-line-without-width {
  font-size: 34px;
  font-weight: 550;
  line-height: 1.3;
}

.our-exp-descr-font22-without-width {
  font-size: 22px;
  line-height: 1.5;
}


.our-exp-head-line {
  font-size: 34px;
  font-weight: 550;
  line-height: 1.3;
 // max-width: 550px;
}

.our-exp-head-line_r{
  font-size: 34px;
  font-weight: 550;
  line-height: 1.3;
  max-width: 550px;
}

.our-exp-descr-font22 {
  font-size: 22px;
  line-height: 1.5;
  max-width: 500px;
}

.our-exp-descr-2{
  font-size: 22px;
  line-height: 1.5;
}
.counter-bgr {
  background-image: url("/images/counter-bgr.png");
  background-color: #019fe3;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 700px;
 // background-position-y: center;
}

.offer-bg-color{
  background-color: #019fe3;
}

.footer-font{
  font-weight: 600;
  font-size: 18px;
}

.mr-top-140{
  margin-top: 140px
}

.font-48 {
  font-size: 48px;
}
.font-34{
  font-size: 34px;
}
.font-24{
  font-size: 24px;
}

.font-32{
  font-size: 32px;
}

.font-30{
  font-size: 30px;
}

.font-20{
  font-size: 20px;
}

.font-22{
  font-size: 22px;
}
.font-26{
  font-size: 26px;
}
.font-18{
  font-size: 18px;
}

.font-white {
  color: white;
}

.counter {
  padding-top: 6%;
}

.counter-icon-box {
  display: flex;
}

.max-width-600{
  max-width: 600px;
}

.devices-icon {
  background: url(/images/devices-24px.png);
  background-repeat: no-repeat;
  background-position-x: center;
  display: block;
  height: 89px;
  width: 108px;
}
.kns_qp_bg{
  background: url(/images/quality_protect/kns_qp_bg_section.png);
}
.kns_qp_section{
  line-height: 580px;
  height: 580px;
  text-align: center;
}
.kns_qp_section p {
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
  font-weight: 550;
}

.border_bottom_index{
  border-bottom-width: 1px  rgb(255, 255, 255);
  width: 200px;
  opacity: 0.7;
}

.groups-icon {
  background: url(/images/groups-24px.png);
  background-repeat: no-repeat;
  background-position-x: center;
  display: block;
  height: 89px;
  width: 89px;
}

.events-icon {
  background: url(/images/events-24px.png);
  background-repeat: no-repeat;
  background-position-x: center;
  display: block;
  height: 89px;
  width: 89px;
}

.thumb-icon {
  background: url(/images/thumb-24px.png);
  background-repeat: no-repeat;
  background-position-x: center;
  display: block;
  height: 89px;
  width: 89px;
}

.counter-header {
  margin-top: 7%;
}

.float-r{
  float: right;
}

.pad-l-240{
  padding-left: 240px;
}

.padding-left-22p {
  padding-left: 22%;
}
.steps-section {
  background-image: url("/images/steps_section_bgr.png");
  background-color: #dee6fb;
  background-size: contain;
  background-repeat: no-repeat;
  //height: 2000px;
  .container-fluid {
    padding: 160px 200px 0 200px;
  }
}
.container-fluid-2 {
  padding: 0px 200px 0 200px;
}
.offer_text_with_triangle {
  padding-left: 0;
  padding-bottom: 15px;

  &:before {
    content: url(/images/triangle_left.png);
    position: absolute;
    top: -12px;
    left: -24px;
  }

}
.section-container {
  padding: 0 200px;
}
.counter-container {
  padding: 0 180px;
}

.bg-contact{
  background-color: #f2f2f2;
}

.hm-530{
  min-height: 530px;
}

.float-left{
  float: left;
}

.font-contact{
  font-size: 14px; 
  margin-left: 43px;
}

/*****************************
  Case Study
*****************************/
.case-study {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px; }
  .case-study .case-study-title {
    display: block;
    color: #ffffff;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    opacity: 1; }
    .case-study .case-study-title:hover {
      color: #022d62; }
  .case-study .case-study-services {
    display: block;
    margin-bottom: 10px; }
    .case-study .case-study-services:hover {
      color: #ffffff; }
  .case-study .case-study-img {
    background-size: cover;
    min-height: 249px;
    border-radius: 10px;
    background-position: center center; }
  .case-study .case-study-img.case-study-lg {
    min-height: 630px; }
    .case-study .case-study-img.case-study-lg-02 {
      min-height: 200px; }
  .case-study .case-study-info {
    margin-top: 90;
    border-color: #019fe3;
    border: 3px;
    border-width: 3px;
    min-height: 100%;
    padding: 20px;
    width: 100%;
    position: absolute;
    left: 50%;
    bottom: 0px;
    z-index: 2;
    opacity: 0;
    border-radius: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
    background: #1aa8e6; }
    .case-study .case-study-info p {
      max-height: auto;
      overflow: auto; }
  .case-study:hover .case-study-info {
    background: rgba(4, 149, 215, 0.8);
    bottom: 0px; 
  }

  .case-study:hover .case-study-info .case-study-text {
    opacity: 1;
    bottom: 0px; 
  }
  .case-study .case-study-lg + .case-study-info p {
    max-height: 340px;
    overflow: auto; }

/* style 02 */
.case-study-style-02 {
  position: relative; }
  .case-study-style-02:before {
    position: absolute;
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    top: auto;
    background: rgba(2, 45, 98, 0.85);
    content: "";
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    border-radius: 5px; }
  .case-study-style-02:hover:before {
    bottom: 0;
    height: 100%; }
  .case-study-style-02 .case-study-title {
    color: #ffffff; }
  .case-study-style-02 .case-study-info {
    background: transparent; }
    .case-study-style-02 .case-study-info p {
      color: #ffffff; }

      .case-study-style-04 {
        position: relative; }
        .case-study-style-04:before {
          position: absolute;
          width: 100%;
          height: 0;
          bottom: 0;
          left: 0;
          top: auto;
          background: rgba(2, 45, 98, 0.85);
          content: "";
          -webkit-transition: all 0.5s ease;
          transition: all 0.5s ease;
          border-radius: 5px; }
        .case-study-style-04:hover:before {
          bottom: 0 !important;
          height: 0% !important; }
        .case-study-style-04 .case-study-title {
          color: #ffffff; }
        .case-study-style-04 .case-study-info {
          background: transparent; }
          .case-study-style-04 .case-study-info p {
            color: #ffffff; }


      

.clients-detail {
  margin: 0; }
  .clients-detail li {
    padding-right: 150px;
    margin-top: 20px; }
    .clients-detail li:last-child {
      padding-right: 0px; }

.case-study-style-03 {
  overflow: hidden; }
  .case-study-style-03 .case-study-img {
    -webkit-transition: -webkit-transform .5s ease-in-out;
    transition: -webkit-transform .5s ease-in-out;
    transition: transform .5s ease-in-out;
    transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out;
    overflow: hidden;
    border-radius: 0; }
  .case-study-style-03 .case-study-info {
    padding: inherit;
    width: inherit;
    position: relative;
    left: inherit;
    bottom: inherit;
    z-index: 2;
    opacity: inherit;
    border-radius: inherit;
    -webkit-transform: inherit;
            transform: inherit;
    background: #ffffff;
    padding-top: 20px; }
  .case-study-style-03:hover .case-study-info {
    bottom: inherit;
    height: 100%;
    -webkit-transition: -webkit-transform .5s ease-in-out;
    transition: -webkit-transform .5s ease-in-out;
    transition: transform .5s ease-in-out;
    transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out; }
  .case-study-style-03:hover .case-study-img {
    -webkit-transform: scale(1.07);
            transform: scale(1.07);
    -webkit-transition: -webkit-transform .5s ease-in-out;
    transition: -webkit-transform .5s ease-in-out;
    transition: transform .5s ease-in-out;
    transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out; }
  .case-study-style-03 .case-study-services:hover {
    color: #fe766a; }


.feature-work{
  background-color: white;
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  height: 130px;
  text-align: center;
  font-size: 23px;
  font-weight: 600;
  line-height: 130px;
}





/* Style 06 */
.feature-info-style-09 {
	.feature-info-a {
		margin-bottom: 30px;
    background-color: white;
		a {
			border-radius: $border-radius;
			opacity: 0.5;
			transition: all 0.3s ease-in-out;
		}
	}&:hover {
	.feature-info-a {
			&:after {
				width: 100%;
				background: $gray-9;
				//transition: all 0.3s ease-in-out;
			
			}
			a {
				color: $primary;
				opacity: 1;
				transition: all 0.3s ease-in-out;
			}
		}
	}
}







    /* Responsive View */
@media (max-width: 1199px) {
  .clients-detail li {
    padding-right: 110px; } 
    .pad-abs{
      position:static;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      max-width: 100%;
      height: auto;
    }
  .top-50{
    top: 50px!important;
  }
  .pad-l-30{
    padding: 0 30px!important;
    }
    .pad-l-40{
      padding: 0 40px!important;
      }
    .pad-5{
      padding: 2.5rem!important;
    }
    .mrg-l-0{
      margin-left: 0rem!important;
    }
    .pad-rl-0{
      padding-left: 0rem;
      padding-right: 0rem;
    }
    .pad-rl-1{
      padding-left: 4rem!important;
      padding-right: 1rem!important;
      padding-top: 1rem!important;
    }
    .white-space-mobile{
      white-space:initial;
      line-height: 1.7;
    }
    
    .line-h{
      line-height: 1.7;
    }
    .pad-top-0{
      padding-top: 0 !important;
    }
    .hr-disp-mb{
      left: auto !important;
      box-sizing: border-box !important;
      }
  .reverse-image{
  flex-direction: column-reverse!important;
  bottom: 0px
  img{

  }
}
}

@media (max-width: 991px) {
  .case-study .case-study-info {
    padding: 20px; }
  .clients-detail li {
    padding-right: 50px; } 
    .pad-abs{
      position:static;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      max-width: 100%;
      height: auto;
    }
    .top-50{
      top: 50px!important;
    }
    .pad-l-30{
    padding: 0 30px!important;
    }
    .pad-l-40{
      padding: 0 40px!important;
      }
    .pad-5{
      padding: 2.5rem!important;
    }
    .mrg-l-0{
      margin-left: 0rem!important;
    }
    .pad-rl-0{
      padding-left: 0rem;
      padding-right: 0rem;
    }
    .pad-rl-1{
      padding-left: 4rem!important;
      padding-right: 1rem!important;
      padding-top: 1rem!important;
    }
    .white-space-mobile{
      white-space:initial;
      line-height: 1.7;
    }
    
    .line-h{
      line-height: 1.7;
    }
    .pad-top-0{
      padding-top: 0 !important;
    }
    .hr-disp-mb{
      left: auto !important;
      box-sizing: border-box !important;
      }
  .reverse-image{
  flex-direction: column-reverse!important;
  bottom: 0px
}
  }

@media (max-width: 767px) {
  .clients-detail li {
    padding-right: 50px;
    width: 50%;
    float: left; } 
    .pad-abs{
      position:static;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      max-width: 100%;
      height: auto;
    }
    .top-50{
      top: 50px!important;
    }
    .pad-l-30{
      padding: 0 30px!important;
      }
      .pad-l-40{
        padding: 0 40px!important;
        }
    
      .pad-5{
        padding: 2.5rem!important;
      }
      .mrg-l-0{
        margin-left: 0rem!important;
      }
      .pad-rl-0{
        padding-left: 0rem;
        padding-right: 0rem;
      }
      .pad-rl-1{
        padding-left: 4rem!important;
        padding-right: 1rem!important;
        padding-top: 1rem!important;
      }
      .white-space-mobile{
        white-space:initial;
        line-height: 1.7;
      }
      
      .line-h{
        line-height: 1.7;
      }
      .pad-top-0{
        padding-top: 0 !important;
      }
      .hr-disp-mb{
        left: auto !important;
        box-sizing: border-box !important;
        }
      .reverse-image{
        flex-direction: column-reverse!important;
        bottom: 0px
      }
  }

@media (max-width: 575px) {
  .clients-detail li {
    padding-right: 0px;
    width: 100;
    float: none; } 
  .pad-abs{
    position:static;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    max-width: 100%;
    height: auto;
  }
  .pad-l-30{
    padding: 0 20px!important;
    }
  .pad-l-40{
    padding: 0 40px!important;
    }
  .top-50{
    top: 50px!important;
  }
  .pad-5{
    padding: 2.5rem!important;
  }
  .mrg-l-0{
    margin-left: 0rem!important;
  }
  .pad-rl-0{
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .pad-12{
    padding: 12px!important;
  }
  .bg-dark-half-md:before,
.bg-light-half-md:before {
    height: 610px;
}

.bg-light-jp{
background-color: #7c97f2;
}

.pad-rl-1{
  padding-left: 4rem!important;
  padding-right: 1rem!important;
  padding-top: 1rem!important;
}

.white-space-mobile{
  white-space:initial;
  line-height: 1.7;
}

.line-h{
  line-height: 1.7;
}
.pad-top-0{
  padding-top: 0 !important;
}

.pad-bottom-0{
  padding-bottom: 0 !important;
}

.hr-disp-mb{
left: auto !important;
box-sizing: border-box !important;
}
.reverse-image{
  flex-direction: column-reverse!important;
  padding-bottom: 0px;
}
.space-mob{
  padding-bottom: 0px;
  padding-top: 0px;
}

.space-b-mob{
  padding-bottom: 0px !important;
}
.nav-item{
  justify-content: center!important;
  display: flex !important
  ;
}
.active-nav{
  border-bottom: 2px solid $primary;
 //padding: 7px 0px !important;
  color: $primary !important;

}

.disp-block{
  display: block !important;
}
.margin-t-m{
  margin-top: 20px !important;
}
.text-align-c{
text-align: center !important;
}

.mx-height{
  min-height: 530px !important;
}
.bg{
  background-image: url(/images/section-bgr.png) !important;
  background-position: 90px -15px !important;
  background-size: cover;
}

.bg-3{
  background-image: url("/images/realizacje/step_section_rlz_bgr2.png") !important;
}

.bg-4{
  background-image: url("/images/index/bg-4.png") !important;
}
.bg-2{
  background-image: url(/images/section-bgr.png) !important;
  background-position: 25px -55px !important;
  background-size: initial;
}

.h3_span_2{
  font-size: 36px !important;
}

.font-38{
  font-size: 38px !important;
}

.max-resp{
  max-height: 274px !important;
  width: 100% !important;
  
}

.div-map{
  width: 100% !important;
}

.offer-header{
  font-size: 42px !important;
  text-align: unset !important;
  line-height: normal !important;
}

.offer-header-r{
  line-height: 318px !important;
  padding-right: 15px !important;
}

.mtm-0{
  margin-top: 0rem !important;
}
}