// Font family
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

$font-base: 		   'Titillium Web', sans-serif;
$font-hedding:		  'Titillium Web', sans-serif;

// Colors
$body-color: 				#676c71; // body-text
$primary:    				#fe766a;
$blue:                      #20abe6;
$white:		   				#ffffff;
$gray-1:			 		  #f6f6f6; 	// bg light
$gray-2:			 		  #dfdfdf;
$gray-3:			 		  #626262;
$gray-4:			 		  #707173;
$gray-5:			 		  #3a4957;
$gray-6:			 		  #293745;
$gray-7: 		 				#1b1b29;
$gray-8: 		 				#022d62; 	// hedding color
$gray-9: 		 				#7c97f2; 	// bg color
$black: 	 	 				#000000;

// Border color
$border-color: 			#eeeeee;
$border-color-2:        #bdd0fb;
// For button and input border radius
$border-radius      : 5px;
$border-radius-18 	: 18px;
$border-radius-50px	: 50px;
$border-radius-50 	: 50%;
$border-radius-100 	: 100%;

$padding-x  : 200px;
$container-padding-x: 200px;

// Box shadow sizes
$box-shadow         : 2px 2px 4px 0px rgba(2, 45, 98, 0.1);
$boxshadow-lg       : 5px 5px 24px 0px rgba(2, 45, 98, 0.1);

