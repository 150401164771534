/*************************
        Portfolio
*************************/

.portfolio-item {
  position: relative;
  overflow: hidden;

  &:before{
    position: absolute;
    content: "";
    background: $gray-9;
    width: 100%;
    height: 100%;
    opacity: 0.3;
  }

  &:hover {
    .portfolio-overlay {
      background: $white;
      opacity: 1;
    }

    .portfolio-info {
      bottom: 20px;
    }

    .portfolio-icon {
      top: 20px;
    }

  }

}

.portfolio-overlay {
  padding: 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  opacity: 0;
  height: 100%;
  align-items: flex-end;
  transition: all 0.5s ease;
  color: $white;

  .portfolio-title {
    color: $primary;
  }

}

.portfolio-info {
  position: absolute;
  bottom: 0px;
  transition: all 0.5s ease;
  width: 100%;
  left: 0px;
  padding-left: 20px;
}

.portfolio-icon {
  position: absolute;
  top: 0px;
  left: 0px;
  transition: all 0.5s ease;
  align-self: center;
  padding-left: 20px;

  span {
    color: $black;
    font-size: 18px;
  }

  i {
    color: $primary;
    font-size: 20px;
  }

}


/*************************************
         Shuffle
 *************************************/

.my-shuffle-container.columns-1 {
  .grid-item {
    width: 100%;
    padding: 15px;
  }

}

.my-shuffle-container.columns-2 {
  .grid-item {
    width: 50%;
    padding: 15px;
  }

}

.my-shuffle-container.columns-3 {
  .grid-item {
    width: 33.33333333%;
    padding: 15px;
  }

}

.my-shuffle-container.columns-4 {
  .grid-item {
    width: 25%;
    padding: 10px;
  }

}

.my-shuffle-container.columns-5 {
  .grid-item {
    width: 20%;
    padding: 15px;
  }

}


.my-shuffle-container.columns-6 {
  .grid-item {
    width: 16.666666%;
    padding: 0px;
  }

}


.my-shuffle-container .portfolio-item{
  position: relative;
  filter: contrast(0.8);
  transition: all 0.5s ease;
}

.my-shuffle-container .portfolio-item:hover{
  filter: contrast(1);
}



@media (max-width: 1199px) {

.my-shuffle-container.columns-6 {
  .grid-item {
    width: 25%;
  }

}

}


@media (max-width: 991px) {

.my-shuffle-container.columns-6 {
  .grid-item {
    width: 33%;
  }

}

}



@media (max-width: 767px) {
  .my-shuffle-container.columns-3 {
    .grid-item {
      width: 50%;
    }

  }

  .my-shuffle-container.columns-4 {
    .grid-item {
      width: 50%;
    }

  }

  .my-shuffle-container.columns-5 {
    .grid-item {
      width: 50%;
    }

  }

  .my-shuffle-container.columns-6 {
    .grid-item {
      width: 50%;
    }

  }

}

@media (max-width: 575px) {
  .my-shuffle-container.columns-2 {
    .grid-item {
      width: 100%;
    }

  }

  .my-shuffle-container.columns-3 {
    .grid-item {
      width: 100%;
    }

  }

  .my-shuffle-container.columns-4 {
    .grid-item {
      width: 100%;
    }

  }

  .my-shuffle-container.columns-5 {
    .grid-item {
      width: 100%;
    }

  }

  .my-shuffle-container.columns-6 {
    .grid-item {
      width: 100%;
    }

  }

}
